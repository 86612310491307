@import '../node_modules/antd/dist/antd.css';

/* 字體 */
@import url('https://fonts.googleapis.com/css2?family=Yuji+Hentaigana+Akari&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&family=Yuji+Hentaigana+Akari&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');

.container-full{
  width: 100vw;
  height: auto;
  margin: 0;
  padding: 0;
}
.container-fluid{
  width: 1280px;
  height: auto;
  align-self: center;
  margin: 0 auto;
}
.container-midtern{
  width: 1080px;
  height: auto;
  align-self: center;
  margin: 0 auto;
  padding: 0 15px;
}
.container-100vh{
  height: 100vh;
}
/* normal one  */
.container{
  width: 100%;
  height: auto;
  display: flex;
}

.work-container{
  width: 90%;
  height: auto;
}
/*--------------- color ------------------*/
:root {
  --dark-color: #252422;
  --light-color: #CCC5B9;
  --bgc-color: #e7e7e7;
  --high-color: #EB5E28;
}

/*--------------- setting ------------------*/
body {
  margin: 0;
  padding: 0;
  font-size: 18px;

}

.layout-header{
  height: 70px;
  background-color: #fff;
  position: sticky;
	top: 0;
  z-index: 99;
}

.layout-footer {
  height: 50px;
  background-color: var(--dark-color);
  color: #fff;
  font-family: 'Yuji Hentaigana Akari';
  padding: 15px 0;
  text-align: center;
  
}
.layout-content {
  height: auto;
  background-color: #fff;
}

.mb-20{
margin-bottom: 20px;
}
.mb-40{
  margin-bottom: 40px;
}
.mb-60{
  margin-bottom: 60px;
}
.mt-20{
  margin-top: 20px;
}
.mt-40{
  margin-top: 40px;
}
.mt-5{
  margin-top: 5px;
}
.mt-0{
  margin-top: 0px;
}
/* --------------------header--------------------- */

.site-name{
  color: var(--dark-color);
  font-size: 25px;
  font-family: 'Yuji Hentaigana Akari';
}
/* navbar */
.navbar-item{
  float: right;
  color: var(--dark-color);
  font-size: 20px;
  font-family: 'Yuji Hentaigana Akari';
  padding: 0 20px;

}
.rwd-menu{
  height: 100vh;
}
.rwd-navbar-item{
  color: var(--dark-color);
  font-size: 20px;
  font-family: 'Yuji Hentaigana Akari';

}
/* -----------------------------------------main----------------------------- */
/*----------- icon--------- */
.icon-menu{
  display: none;
  float: right;
  padding-top: 20px;
}
/*----------- image--------- */
.width-image{
  width: 100%;
  height: auto;
  position: absolute;
  opacity: 0.3;
}
.sub-width-image{
  width: 100%;
  height: 150px;
  position: absolute;
  opacity: 0.3;
}

.icon-image{
  width: 150px;
  height: auto;
  border-radius: 20px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.cate-title {
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.8s ease;
  position: relative;
  background-color: var(--light-color);
}

.cate-title::before,
.cate-title::after {
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
}

/* btn effect */
.btn5 {
  overflow: hidden;
  transition: all 300ms ease;
}

.btn5 h4 {
  color: var(--dark-color);
}

.btn5:after {
  width: 300%;
  height: 300%;
  left: -300%;
  transform: rotate(30deg);
  background-color: var(--dark-color);
  color: var(--light-color);
  transition: all 300ms ease;
}

.btn5:hover h4 {
  color: var(--light-color);
  z-index: 10;
  font-weight: 600;
}

.btn5:hover::after {
  left: -100%;
}


.item-image{
  width: 20vw;
  height: auto;

}

/* --------popover-------- */
.contact-image{
  width: 60vw;
  height: auto;
  margin: auto;
}
.skill-image{
  width: 25px;
  height: auto;

}
.skill-popover{
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 3px;
  border-radius: 10px;
  width: 180px;
}
.skill-popover-contact{
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 3px;
  border-radius: 10px;
  width: 320px;
  letter-spacing: 1px;
  
}
.skill-item{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 16px;
  margin: 10px;
  
}

/*----------- box--------- */
.box-about{
  margin: 30px;
}
.color-bg{
  background-color: #ec9a9a1e;
}

.box-title{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 48px;
}
.box-slogan{
  color: var(--high-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 48px;

}
.box-content-p{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
}
.more-btn{
  width: 100px;
  height: auto;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  background-color: var(--high-color);
  color: #ffffff;
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
}
.more-btn:hover{
  cursor: pointer;
}
/*-----------block--------- */

.block-title{
  color: var(--dark-color);
  font-family: 'Yuji Hentaigana Akari', sans-serif;
  font-size: 20px;
}
.block-item{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.block-time{
  color: var(--light-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 16px;
}
.block-name{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 16px;
}
.block-race{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}
.block-class{
  color: var(--light-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 14px;
}
.block-content{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 14px;
}




/*-----------------------web------------------------- */
.ntue-content{
  height: 30vh;
  padding: 15px;
  background-color: #1a252f;
}
.boxtitle{
  margin-top: 30px;
}
.title{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 35px;
  text-align: center;
  padding: 50px 0;
}
.subtitle{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
  text-align: center;
  margin: 25px 0 ;
}

/*-----------Learning--------- */

input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/*--------------------------Works_image----------------------- */
figure {
	margin: 0;
	padding: 0;

}
.item-intro{
  font-size: 18px;
  text-align: center;
}
/*-----------design------------------------------------ */

.box-design{
  width: 1280px;
  height: auto;
  align-self: center;
  margin: 0 auto;
}
/*-----------Craft--------- */
.design-item{
  background-color: var(--dark-color);
  padding: 25px;
  margin: 25px;
  position: relative;
}
.design-decoration{
  width: 22vw;
  height: 55vh;
  border: 3px solid var(--dark-color);
  position: absolute;
  margin-top: 30px;
  margin-left: 5px;
  
}
.design-image{
  width: 100%;
  height: auto;
}
.design-title{
  color: var(--light-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
  margin-top: 10px;
}
.design-date{
  color: var(--light-color);
  font-family: 'Exo 2', sans-serif;
  margin-top: 10px;
  opacity: 0.5;
  font-size: 15px;

}
/*-----------UIUX--------- */
.design-name{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
  font-weight: 600;

}
.design-slogan{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 15px;
}
.design-concept{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 15px;
  opacity: 0.7;
  margin-top: 20px;
}
.design-tag{
  color: var(--high-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 15px;
  margin-top: 10px;
}
.design-award{
  color: var(--dark-color);
  border-bottom: 1px dashed var(--light-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 13px;
  opacity: 0.7;
}

/*-----------programme--------- */
.design-program{
  width: 1280px;
  margin: auto;
  margin-top: 20px;
}
.design-program-box{
  background-color: #ffffff;
  margin: 0 20px;
}
.design-program-image{
  margin: 0 30px;
  position: absolute;
}
.design-program-image-col2{
  margin: 0 45px;
  position: absolute;
}
.design-program-words{
  background-color: var(--light-color);
  color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  position: relative;
  margin-top: 180px;
}
.design-program-title{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
  position: absolute;
}
.design-program-date{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  opacity: 0.5;
  font-size: 15px;
  text-align: right;
  position: relative;
}
.design-program-content{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 16px;
  margin-top: 15px;
}
.design-program-youtube{
  color: #fff;
  font-family: 'Exo 2', sans-serif;
  font-size: 16px;
  margin-top: 15px;
}


/*-----------Detail-------------------------------------- */

/*-----------program detail--------- */
.design-box-program{
  width: 1280px;
  height: auto;
  margin: 25px auto;
  background-color: var(--bgc-color);
}
.design-detail-words{
  background-color: var(--light-color);
  padding: 30px;

}


/*-----------product detail--------- */
.design-video{
  margin-top: 20px;
  margin-left: 20px;
}
.design-detail-tag{
  color: var(--high-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
  margin-top: 20px;
}
.design-detail-name{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 30px;
  font-weight: 600;
}
.design-detail-concept{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 15px;
  margin-top: 10px;
}

.design-detail-title{
  color: var(--dark-color);
  font-size: 25px;
  font-family: 'Yuji Hentaigana Akari';

}
.design-detail-content{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 15px;
  margin-top: 15px;
  background-color: #d1d1d139;
  padding: 15px;
}

.design-box{
  width: 1080px;
  height: auto;
  margin: 100px auto;

}
.design-detail-box{
  height: 80vh;
}

.box-Design{
  width: 1080px;
  height: auto;
  align-self: center;
  margin: 0 auto;
}
.design-content{
  padding: 0 10px;
}

.item{
  margin: auto;
}

.arrow{
  color: var(--high-color);
  width: 100%;
  height: auto;
  font-size: 25px;
  text-align: center;
  margin-top: 100px;
  transform: rotate(45deg);
  position: relative;
  animation: bounce 2s infinite; /* 添加动画效果 */
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(10px); /* 定义向下的动画效果 */
  }
}
.contact-title{
  color: var(--dark-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 35px;
  text-align: center;
}
.contact-btn{
  width: 150px;
  height: auto;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  background-color: var(--high-color);
  color: #ffffff;
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
  margin: 10px 25px;
}
.contact-btn:hover{
  cursor: pointer;
}
.back-btn{
  width: 80px;
  height: auto;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--light-color);
  color: var(--light-color);
  font-family: 'Exo 2', sans-serif;
  font-size: 16px;
  margin: 10px 25px;
}

/* RRRRRRRRRRRRRWWWWWWWWWWWWWWWWWWWWWWWWDDDDDDDDDDDDDDDDDDDDDD */
@media(max-width: 750px){
  html{
    width: 100vw;
  }
  .container-fluid{
    width: 100vw;
  }
  .container-rwd{
    width: 800px;
  }
  .container-midtern{
    width: 100vw;
    height: 60vh;
  }
  .navbar-nav{
    display: none;
  }
  .icon-menu{
    display: block;
  }
  /* -------- main page ----------- */
  .box-about{
    display: block;
  }
  .box-content-p{
    width: 90vw;
  }
  .more-btn{
    display: none;
  }
  .myself-image{
    width: 150px;
    height: auto;
    margin-left: 30%;
  }
  .skill-popover{
    width: 85vw;
    margin-top: 20px;
    padding: 20px;
    background-color: #d8d8d877;
  }
  .skill-item{
    margin: 10px ;
  }
  .cate-box{
    display: block;
  }
  .cate-title{
    width: 90vw;
    height: 80px;
    margin: 10px 20px;
    
  }
  .block{
    width: 90vw;
  }
  .layout-header{
    background-color: var(--bgc-color);
  }
  /* font size */
  .site-name{
    font-size: 20px;
  }
  .title{
    font-size: 25px;
  }
  .box-title{
    font-size: 14px;
  }
  .box-slogan{
    font-size: 20px;
    width: 200px;
  }
  .box-content-p{
    font-size: 14px;
    width: 80vw;
  }
  .skill-item{
    font-size: 14px;
  }
  .block-item{
    font-size: 16px;
  }
  .block-name{
    font-size: 14px;
  }
  .block-class{
    font-size: 14px;
  }
/* -------- other page ----------- */
.boxtitle{
  height: 150px;
}  
.carousel{
    display: none;
  }
  
  .design-decoration{
    display: none;
  }
  .rwd-design{
    display: block;
  }
  .design-program{
    display: block;
    width: 100vw;
  }
  .design-program-box{
    width: 75vw;
  }
  .design-program-box-row{
    display: block;
  }

  .design-box{
    margin-top: 0;
  }
  .design-detail-box-row{
    display: block;
    width: 180vw;
    margin: 20px;
  }

  .design-video-iframe{
    width: 80vw;
    height: auto;
  }
  .arrow{
    display: none;
  }
  .detail-row{
    display: none;
  }
  .item-box, .design-item{
    width: 100vw;
  }
  .item-box-row{
    display: block;
    width: 100vw;
  }
  .design-content{
    width: 90vw;
    margin: 0 20px;
  }
  .project-image{
    width: 50vw;
    margin: 0 50%;
  }
  .box-Design, .box-design{
    width: 100vw;
  }


  /* font size */
  .design-detail-name{
    font-size: 25px;
  }
  

}